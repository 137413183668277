<template>
	<el-popover v-model="visible" :title="title" :placement="placement" :width="width">
		<slot>{{content}}</slot>
		<slot name="reference" slot="reference" />
		<div class="row" align-x="right" style="margin-top:1em;">
			<el-button type="text" size="mini" @click="handleClick('cancel')">取消</el-button>
			<el-button type="primary" size="mini" @click="handleClick('confirm')">確認</el-button>
		</div>
	</el-popover>
</template>

<script>
export default {
	props: ["title", "content", "placement", "width"],
	data() {
		return {
			visible: false
		}
	},
	methods: {
		handleClick(action) {
			this.$emit(action);
			this.visible = false;
		}
	}
}
</script>